import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const CotivationPageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section class="pt-6 pt-md-11 pb-11 pb-md-13 bg-dark">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 col-xl-6 text-center text-white">
              <h6 class="text-uppercase text-warning mb-5">
                Cotivation Mastermind Program
              </h6>

              <h5 class="display-3 mb-4">🚀</h5>
              <h1 class="display-5 mb-4">Level Up with Members Like You</h1>

              <p class="font-size-md">
                Many of our members join Reno Collective to create a sense of
                accountability for themselves. Having a physical space to get
                work done is a great start but sometimes we all need a little
                bit of accountability too. To do that, we run a mastermind
                program called Cotivation.
              </p>

              <h2 class="display-5 mb-5">What is Cotivation?</h2>

              <p class="font-size-md">
                Cotivation* is an eight-week program, with members meeting on a
                weekly basis to set goals and revisit previous commitments.
              </p>

              <p class="font-size-md">
                This means that every member has a chance to make progress with
                the help of fellow coworkers.
              </p>

              <p class="font-size-md">
                Weekly meetings ensure everyone has a sense of accountability as
                well as ongoing guidance from helpful peers. Challenges are
                routinely identified, tackled, then re-examined, so you can feel
                a sense of progress in your work but also make strides in your
                business or personal growth.
              </p>

              <h2 class="display-5 mb-5">Ready to get started?</h2>

              <p class="font-size-md">
                <a href="#next-program" style={{ color: '#F5C070' }}>
                  Check out the next program below!
                </a>
              </p>

              <p class="font-size-sm">
                *<strong>Community Hat Tip</strong>: The Cotivation format was
                originally created by{' '}
                <a
                  href="https://twitter.com/tonybgoode"
                  target="_blank"
                  style={{ color: '#F5C070' }}
                >
                  Tony Bacigalupo
                </a>{' '}
                and{' '}
                <a
                  href="https://twitter.com/nomadicq"
                  target="_blank"
                  style={{ color: '#F5C070' }}
                >
                  Susan Dorsch
                </a>{' '}
                and we have adapted and evolved it to fit the Reno Collective
                community over the last 4 years.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="py-10 py-md-12">
        <div class="container-lg">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6">
              <h2 id="next-program" class="display-3 text-center mb-4">
                Winter 2025 Cotivation
              </h2>

              <p class="text-muted">
                Our Winter Cotivation 2025 program will kick off on January 10th
                with meetings on Fridays.
              </p>

              <ul style={{ listStyleType: 'none' }}>
                <li>Week 1 - January 10</li>
                <li>Week 2 - January 17</li>
                <li>Week 3 - January 24</li>
                <li>Week 4 - January 31</li>
                <li>Week 5 - February 7</li>
                <li>Week 6 - February 14</li>
                <li>Week 7 - February 21</li>
                <li>Week 8 - February 28</li>
              </ul>

              <p class="text-muted">
                If you'd like to join us for the February program, Reno Collective
                members can apply today.
              </p>

              <p class="text-center text-primary">
                Sign up coming in December!
              </p>

              <p class="text-muted">
                Cotivation is available to active Reno Collective members. If
                you are not yet a member,{' '}
                <Link to="/join">you can learn more here</Link>.
              </p>

              <h2 class="display-5 mb-5">Upcoming Programs</h2>

              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <strong>Winter 2025:</strong> Coming January 2025
                </li>
                <li>
                  <strong>Spring 2025:</strong> Coming April 2025
                </li>
                <li>
                  <strong>Summer Break!</strong> ☀️ 😎{' '}
                </li>
                <li>
                  <strong>Fall 2025:</strong> September 202
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

CotivationPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CotivationPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CotivationPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

CotivationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CotivationPage

export const cotivationPageQuery = graphql`
  query CotivationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
